.transfer-menu {
  .header {
    h4 {
      font-size: 24px !important;
      text-align: center;
    }
  }
  .actions {
    display: flex;
    flex-direction: column;

    .trigger {
      padding: 20px;
      margin: 10px;
      background-color: #4e73df;
      color: #fff;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 5px;
      border: 0;

      img {
        width: 32px;
        height: 32px;
      }

      h4 {
        font-weight: 600;
        margin-left: 10px;
      }
    }
    .trigger:hover {
        background-color: #e74a3b;
    }
  }
}

@media (min-width: 700px) {
  .transfer-menu {
    .header {
      h4 {
        text-align: left;
      }
    }
    .actions {
      .trigger {
        max-width: 300px;
      }
    }
  }
}