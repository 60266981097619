.transactions {
  .transaction {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    &.credit{
      color: #3c3c4c;
    }
    &.debit{
      color: #6d6d4c;
    }
    p {
        font-style: italic;
    }
    span {
        display: none;
    }
  }
}

@media (min-width: 700px) {
  .transactions {
    .transaction {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
          height: 15px;
          width: 1px;
          background: #4c4c4c;
          margin: 0 10px;
          display: block;
      }
    }
  }
}
